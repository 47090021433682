:root {
  --black: #222222;
  --crimson: #ed1d24;
  --yellow: #d8be54;
  --pink: #da29ac;
  --purple: #8d09f7;
  --indigo: #5e2bff;
  --darkviolet: #8d09f7;
  --mediumpurple: #9381ff;
  --transparentmediumpurple: #9481ff17;
  --candypink: #ff70a6;
  --chocobrown: #c05d1f;
  --darkolivegreen: #674e38;
  --peru: #aa7756;
  --darkgray: #2a2928;
  --midgray: #838383;
  --lightgray: #e0e0e0;
  --transparentLightGray: #e6e4e4d2;
  --offwhite: #f3f3f1;
  --regularFont: "Comfortaa", sans-serif;
  --headingFont: "Raleway", sans-serif;
  --monospaceFont: "Roboto Slab", serif;
}

body {
  margin: 0;
  font-family: var(--regularFont);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.contentWrapper {
  position: relative;
  padding: 1px;
  border-radius: 8px;
}

.backgroundContainer {
  border-radius: 10px;
  transition: opacity 1.5s ease 0s;
  opacity: 0.85;
  background: conic-gradient(
    from 217.29deg at 51.63% 52.16%,
    var(--chocobrown) 0deg,
    var(--darkolivegreen) 19.5deg,
    var(--peru) 102.75deg,
    var(--chocobrown) 152.25deg,
    var(--darkolivegreen) 208.88deg,
    var(--peru) 291deg
  );
  padding: 0.1rem;
  width: 75%;
}

.icon {
  height: 1rem;
  width: auto;
  color: var(--black);
  margin-right: 0.25rem;
}

.contentContainer {
  background: var(--black);
  border-radius: 10px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.textContainer {
  position: relative;
  background: white;
  border: 2px solid transparent;
  border-radius: 8px;
  padding: 11px 24px 11px 16px;
}

.split {
  position: absolute;
  width: 50%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header {
  font-size: 2rem;
  color: var(--offwhite);
  font-family: var(--headingFont);
  margin: 0;
  white-space: nowrap;
}

.subtext {
  color: var(--offwhite);
  margin-bottom: 1rem;
  font-size: 0.7rem;
  width: 80%;
  text-align: center;
}

.button {
  cursor: pointer;
  text-decoration: none;
  background-color: var(--offwhite);
  color: var(--darkgray);
  width: 10rem;
  border: 1px solid var(--offwhite);
  border-radius: 10px;
  padding: 0.5rem;
  font-family: var(--monospaceFont);
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.05rem;
  font-size: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button:hover {
  background-color: white;
}

@media (max-width: 900px) {
  .split {
    width: 100%;
    height: 50%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 600px) {
  .contentContainer {
    padding: 1rem 0.5rem;
  }

  .header {
    font-size: 1.5rem;
  }

  .subtext {
    font-size: 0.65rem;
  }
}

@media (max-width: 400px) {
  .header {
    font-size: 1.25rem;
  }

  .subtext {
    font-size: 0.6rem;
  }
  .button {
    cursor: pointer;
    width: 9rem;
    border: 1px solid var(--offwhite);
    border-radius: 10px;
    padding: 0.4rem;
    font-size: 0.6rem;
  }
}

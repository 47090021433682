.container {
  position: relative;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: var(--offwhite);
  font-family: var(--regularFont);
}

.logoContainer {
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 99;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.logoText {
  color: var(--black);
  letter-spacing: 0.1rem;
  font-family: var(--monospaceFont);
  font-size: 1.5rem;
  margin: 0 1rem;
  text-align: end;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
}

.footer {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  color: var(--offwhite);
  font-family: var(--headingFont);
  font-size: 0.8rem;
}

.icon {
  width: 1rem;
  height: auto;
  margin-right: 0.25rem;
}

.linkTitle {
  font-weight: bold;
}

.link {
  text-decoration: none;
  color: var(--offwhite);
  margin-left: 0.25rem;
}

.link:hover {
  text-decoration: underline;
}

.monke {
  width: 3.25rem;
  height: auto;
}

.contentContainer {
  overflow-y: auto;
  background-color: var(--offwhite);
  position: relative;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
}

.contentWrapper {
  position: relative;
  padding: 1px;
  border-radius: 8px;
  margin-right: 2rem;
}

.left {
  left: 0;
  background: url(../../images/jon-flobrant-lRSChvh1Mhs-unsplash.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.right {
  right: 0;
  background: url(../../images/roberto-nickson-bwIqQ5qQhXs-unsplash.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.leftHover.left,
.rightHover.right {
  transition: all 0.7s ease-in-out;
  width: 75%;
}

.leftHover.right,
.rightHover.left {
  transition: all 0.7s ease-in-out;
  width: 25%;
}

.backgroundContainer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  filter: blur(1px);
  border-radius: 8px;
  transition: opacity 1.5s ease 0s;
  opacity: 0.75;
  background: conic-gradient(
    from 217.29deg at 51.63% 52.16%,
    rgb(98, 0, 255) 0deg,
    rgb(0, 153, 255) 19.5deg,
    rgb(29, 255, 161) 102.75deg,
    rgb(212, 245, 27) 152.25deg,
    rgb(255, 191, 53) 208.88deg,
    rgb(255, 30, 199) 291deg
  );
}

.textContainer {
  position: relative;
  border: 2px solid transparent;
  border-radius: 8px;
  padding: 6px 12px 6px 8px;
  cursor: pointer;
  background-color: #222222;
}

.linkContainer {
  display: flex;
  color: var(--offwhite);
}

@media (max-width: 1200px) {
  .left {
    top: 0;
  }

  .right {
    bottom: 0;
  }

  .leftHover.left,
  .rightHover.right {
    transition: all 0.7s ease-in-out;
    width: 100%;
    height: 60%;
  }

  .leftHover.right,
  .rightHover.left {
    transition: all 0.7s ease-in-out;
    width: 100%;
    height: 40%;
  }

  .container {
    flex-direction: column;
  }

  .contentContainer {
    width: 100%;
    min-height: auto;
    margin-left: 0;
  }

  .footer {
    width: 40%;
  }
}

@media (max-width: 900px) {
  .content {
    padding: 1rem 0;
  }
  .footer {
    width: 60%;
  }
}

@media (max-width: 600px) {
  .footer {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .footer {
    width: 100%;
    font-size: 0.65rem;
  }

  .monke {
    width: 2rem;
    height: auto;
    margin-bottom: 0.25rem;
  }

  .logoText {
    letter-spacing: 0.1rem;
    font-size: 1rem;
    margin: 0 0.75rem;
  }
}

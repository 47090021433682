.mainLoaderContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.monkeyIcon {
  height: 5rem;
  width: auto;
  margin: 3rem 0 1rem 0;
}

.text {
  font-size: 0.9rem;
  font-family: var(--regularFont);
}

.carBackground {
  flex-grow: 1;
  min-height: 100%;
  min-width: 100%;
  background: url(../../images/luke-stackpoole-x2qSNIEZuEE-unsplash.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.houseBackground {
  flex-grow: 1;
  min-height: 100%;
  min-width: 100%;
  background: url(../../images/roam-in-color-RryFk4n-vOs-unsplash.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.mainWrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.innerWrapper {
  margin: 4rem 0;
  width: 800px;
  background-color: var(--offwhite);
  opacity: 0.9;
  padding: 2rem;
  border-radius: 20px;
}

.mainContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-family: var(--regularFont);
}

.mainResultsContainer {
  display: flex;
  flex-direction: column;
  margin: 0 3rem;
}

.mainResultsHeader {
  text-align: center;
  margin: 0.25rem 0;
  font-size: 0.9rem;
  font-family: var(--monospaceFont);
  color: var(--darkolivegreen);
}

.headingContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.bannerHeading {
  font-family: var(--monospaceFont);
  font-size: 1.5rem;
  margin: 0.75rem;
}

.icon {
  width: 2rem;
  height: auto;
}

.mainHeader {
  font-family: var(--monospaceFont);
  font-size: 1.1rem;
  margin: 1.25rem 0;
  padding-bottom: 0.5rem;
  border-bottom: 1px dotted var(--black);
  text-align: center;
}

.formContainer {
  min-width: 20rem;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  display: inline;
}

.inputField {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.inputField > label {
  width: 10rem;
  height: 1.5rem;
  font-size: 0.8rem;
  margin-right: 0.5rem;
  color: var(--darkgray);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inputField > input {
  width: 8rem;
  font-size: 0.9rem;
  color: var(--darkgray);
  font-family: var(--monospaceFont);
  padding: 0.3rem 0.5rem;
  border: 1px solid var(--midgray);
  border-radius: 4px;
  outline: none;
}

.tooltip {
  font-weight: bold;
  font-size: 1rem;
}

.buttonAndFormWrapper {
  display: flex;
  flex-direction: column;
}

.wrapper {
  border-bottom: 1px solid var(--black);
  margin: 1rem 0 0.5rem 0;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdownContent {
  display: none;
  position: absolute;
}

.dropdownContentActive {
  display: block;
  position: absolute;
  right: 2.75rem;
  top: 2rem;
  background-color: #f9f9f9;
  min-width: 5rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.459);
  z-index: 1;
}

.dropdownContentActive p {
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.973);
  margin: 0;
  padding: 0.5rem;
  text-decoration: none;
}

.dropdownContentActive p:hover {
  cursor: pointer;
  background-color: #f1f1f1;
}

.dropbtn:active {
  transition-duration: 0.2s;
  transform: translate(0.1rem, 0.05rem);
  background: #f8f8f8;
}

.dropbtn {
  font-family: var(--regularFont);
  font-size: 0.9rem;
  border: none;
  padding: 0.6rem 0.8rem;
  background: var(--offwhite);
  border-radius: 10px;
  cursor: pointer;
  min-width: 20rem;
  margin-bottom: 1rem;
}

.dropbtn:focus {
  outline: none;
}

.title {
  font-weight: bold;
  margin-right: 0.5rem;
}

.dropIcon {
  font-size: 0.8rem;
  margin-left: 0.5rem;
}

.dropdownContent .active {
  background: rgba(0, 0, 0, 0.2);
  color: var(--offwhite);
}

.dropdownContent .active:hover {
  background: rgba(0, 0, 0, 0.2);
}

.cashCostDiv {
  border-top: 1px solid black;
  padding-top: 1rem;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  padding: 0 1.35rem;
}

.buttonContainer button {
  padding: 0.4rem 1.25rem;
  text-transform: uppercase;
  font-family: var(--monospaceFont);
  border-radius: 5px;
  outline: none;
  border: none;
  font-weight: bold;
  font-size: 0.75rem;
  cursor: pointer;
  background: var(--darkgray);
  color: var(--offwhite);
}

.buttonContainer button:hover {
  background: var(--midgray);
}

.bold {
  font-weight: bold;
}

.parameter {
  margin: 0.2rem 0;
  display: flex;
  justify-content: space-between;
}

.parameter:last-child {
  font-weight: bold;
  color: var(--darkgray);
  border-top: 1px solid var(--midgray);
  margin-top: 0.5rem;
  padding-top: 0.3rem;
}

.parameterHeader {
  margin: 0.75rem 1rem;
  font-family: var(--monospaceFont);
  font-size: 1.1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px dotted var(--black);
  text-align: center;
}

.parameterWrapper {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
}

.parameterContainer {
  margin: 0.25rem 1rem;
  font-size: 0.85rem;
}

.disclaimer {
  font-size: 0.65rem;
  padding: 1rem 1rem 0rem 1rem;
  margin-top: 1rem;
  color: var(--midgray);
}

.disclaimer strong {
  color: var(--darkgray);
}

.resultsContainer {
  min-width: 20rem;
  padding: 0 1rem;
}

@media (max-width: 900px) {
  .mainContainer {
    flex-direction: column;
  }

  .resultsContainer {
    text-align: center;
  }

  .parameterWrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .innerWrapper {
    width: 90%;
    margin: 4rem 0;
    padding: 1rem;
  }

  .mainHeader {
    margin: 0.75rem 8rem;
  }

  .dropdown {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .inputField {
    display: flex;
    justify-content: center;
  }

  .cashCostDiv {
    display: flex;
    justify-content: center;
    padding-top: 0.5rem;
    margin-bottom: 0.75rem;
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .dropdownContentActive {
    right: 14rem;
    top: 2rem;
  }

  .wrapper {
    text-align: center;
    padding-bottom: 0.25rem;
    margin-right: 10rem;
    margin-left: 10rem;
  }

  .inputField {
    margin-bottom: 0.5rem;
  }

  .inputField > input {
    font-size: 0.7rem;
    padding: 0.15rem 0.3rem;
  }

  .inputField > label {
    display: flex;
    align-items: center;
  }

  .buttonContainer {
    margin-top: 1rem;
  }
}

@media (max-width: 600px) {
  .cashCostDiv {
    margin-left: 0;
    margin-right: 0;
    font-size: 0.9rem;
  }

  .wrapper {
    margin-left: 5rem;
    margin-right: 5rem;
    font-size: 0.9rem;
  }

  .dropbtn {
    margin-bottom: 0.25rem;
  }

  .mainHeader {
    padding-bottom: 0.1rem;
    margin: 0.25rem 4rem 0.5rem 4rem;
    font-size: 1rem;
  }

  .headingContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .inputField {
    margin-bottom: 0.25rem;
  }

  .bannerHeading {
    font-size: 1.25rem;
    margin: 0.25rem;
  }

  .icon {
    width: 1.5rem;
    height: auto;
  }

  .innerWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 0.25rem;
    width: 100%;
    border-radius: 0;
    margin: 4rem 0;
  }

  .mainResultsContainer {
    margin: 0;
  }

  .dropdownContentActive {
    right: 3.5rem;
  }

  .buttonContainer {
    padding: 0 2.55rem;
  }
}

@media (max-width: 400px) {
  .inputField {
    justify-content: space-between;
  }
  .inputField > label {
    width: 10rem;
    margin-right: 0.25rem;
  }

  .inputField > input {
    width: 8rem;
  }
}
